import React from 'react';
import { FaGithub } from 'react-icons/fa';

const teamMembers = [
  {
    name: 'Christopher David',
    role: 'CEO',
    imageUrl: 'https://via.placeholder.com/150', // Reemplaza con tu imagen si tienes una
    github: 'https://github.com/WhiteAssassins',
  },
  {
    name: 'Oscar Andres',
    role: 'Tester',
    imageUrl: 'https://via.placeholder.com/150',
    github: '#',
  },
  {
    name: 'Maria Karla Rodriguez',
    role: 'Tester',
    imageUrl: 'https://via.placeholder.com/150',
    github: '#',
  },
];

const Team = () => {
  return (
    <section id="team" className="py-20 bg-gray-800 text-white dark:bg-gray-900">
      <div className="container mx-auto">
        <h2 className="text-5xl font-bold text-center mb-16 slide-up">Nuestro Equipo</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12">
          {teamMembers.map((member) => (
            <div key={member.name} className="bg-white rounded-lg shadow-lg p-8 dark:bg-gray-700">
              <img src={member.imageUrl} alt={member.name} className="w-32 h-32 rounded-full mx-auto mb-4" />
              <h3 className="font-bold text-2xl text-center mt-4 dark:text-white">{member.name}</h3>
              <p className="text-xl text-gray-700 dark:text-gray-400 text-center">{member.role}</p>
              <div className="flex justify-center mt-4">
                <a href={member.github} className="text-primary hover:text-accent text-2xl transition-colors duration-300">
                  <FaGithub />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;
