import React from 'react';

const PortfolioItem = ({ title, description, imageUrl, link }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-800">
      <div className="relative">
        <img src={imageUrl} alt={title} className="w-full h-56 object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition duration-300 ease-in-out flex justify-center items-center">
          <a href={link} className="text-white bg-primary py-2 px-4 rounded-lg hover:bg-secondary transition duration-300 ease-in-out">Ver Proyecto</a>
        </div>
      </div>
      <div className="p-6">
        <h3 className="font-bold text-2xl dark:text-white">{title}</h3>
        <p className="text-gray-700 dark:text-gray-400 mt-2">{description}</p>
      </div>
    </div>
  );
}

export default PortfolioItem;
