import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const root = window.document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <nav className="bg-primary p-4 dark:bg-gray-800 fixed w-full z-10 top-0">
      <div className="container mx-auto flex justify-between items-center">
        <a href="#" className="text-white text-2xl font-bold">AEWhite Devs</a>
        <div className="space-x-4 flex items-center">
          <a href="#portfolio" className="text-white hover:text-accent">Portafolio</a>
          <a href="#services" className="text-white hover:text-accent">Servicios</a>
          <a href="#team" className="text-white hover:text-accent">Equipo</a>
          <a href="#technologies" className="text-white hover:text-accent">Tecnologías</a>
          <a href="#faq" className="text-white hover:text-accent">FAQ</a>
          <a href="#contact" className="text-white hover:text-accent">Contacto</a>
          <button 
            onClick={() => setIsDarkMode(!isDarkMode)} 
            className="text-white hover:text-accent"
          >
            {isDarkMode ? '☀️' : '🌙'}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
