import React from 'react';
import logo from '../assets/logo.png'; // Asegúrate de que la ruta del logo sea correcta

const HeroSection = () => {
  return (
    <header className="bg-primary py-32 text-center fade-in dark:bg-gray-900">
      <div className="container mx-auto">
        <img src={logo} alt="AEWhite Devs Logo" className="w-80 h-80 mx-auto mb-4" />
        <h1 className="text-5xl font-bold text-white slide-up">Bienvenido a AEWhite Devs</h1>
        <p className="mt-4 text-lg text-gray-200 slide-up">Creamos soluciones digitales para tu negocio</p>
        <a href="#portfolio" className="mt-8 inline-block bg-highlight text-black font-bold py-3 px-6 rounded-full hover:bg-primary transition duration-300 ease-in-out transform hover:scale-105">Explora Nuestro Trabajo</a>
      </div>
    </header>
  );
}

export default HeroSection;
