import React from 'react';
import { FaCode, FaMobileAlt, FaCloud } from 'react-icons/fa';

const Services = () => {
  return (
    <section id="services" className="py-20 bg-gray-900 text-white dark:bg-gray-800">
      <div className="container mx-auto">
        <h2 className="text-5xl font-bold text-center mb-16 slide-up">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">
          <div className="bg-white rounded-lg shadow-lg p-8 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaCode className="text-primary text-6xl mb-6" />
            <h3 className="text-2xl font-bold dark:text-white mb-4">Desarrollo Web</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400">Creamos sitios web modernos, responsivos y optimizados para tu negocio, utilizando las últimas tecnologías y mejores prácticas.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaMobileAlt className="text-primary text-6xl mb-6" />
            <h3 className="text-2xl font-bold dark:text-white mb-4">Aplicaciones Móviles</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400">Desarrollamos aplicaciones móviles intuitivas y de alto rendimiento para iOS y Android, adaptadas a las necesidades específicas de tu negocio.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaCloud className="text-primary text-6xl mb-6" />
            <h3 className="text-2xl font-bold dark:text-white mb-4">Soluciones en la Nube</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400">Implementamos soluciones en la nube seguras y escalables, que permiten a tu negocio operar de manera más eficiente y con mayor flexibilidad.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
