import React from 'react';
import { FaReact, FaNodeJs, FaDatabase, FaPhp, FaHtml5, FaCss3Alt, FaJsSquare, FaWordpress, FaLaravel, FaAndroid, FaPython } from 'react-icons/fa';

const Technologies = () => {
  return (
    <section id="technologies" className="py-16 bg-gray-900 text-white dark:bg-gray-800">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12 slide-up">Tecnologías Utilizadas</h2>
        
        <h3 className="text-3xl font-semibold text-center mb-8">Web</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaReact className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">React</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Construimos interfaces de usuario modernas y reactivas con React.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaNodeJs className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">Node.js</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Desarrollamos aplicaciones del lado del servidor robustas con Node.js.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaPhp className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">PHP</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Desarrollamos aplicaciones web dinámicas con PHP.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaHtml5 className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">HTML5</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Creamos páginas web estructuradas y semánticas con HTML5.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaCss3Alt className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">CSS3</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Diseñamos estilos modernos y responsivos con CSS3.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaJsSquare className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">JavaScript</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Implementamos funcionalidades dinámicas con JavaScript.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaWordpress className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">WordPress</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Desarrollamos y personalizamos sitios web con WordPress.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaLaravel className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">Laravel</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Construimos aplicaciones web robustas con Laravel.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaDatabase className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">Bases de Datos</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Utilizamos bases de datos SQL y NoSQL para almacenar datos de manera eficiente.</p>
          </div>
        </div>

        <h3 className="text-3xl font-semibold text-center mb-8">Escritorio</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaPython className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">Python</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Desarrollamos aplicaciones de escritorio y scripts automatizados con Python.</p>
          </div>
        </div>

        <h3 className="text-3xl font-semibold text-center mb-8">Móvil</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaAndroid className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">Android Studio</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Desarrollamos aplicaciones móviles para Android con Android Studio.</p>
          </div>
        </div>

        <h3 className="text-3xl font-semibold text-center mb-8">Servidores</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-500 ease-in-out dark:bg-gray-700">
            <FaDatabase className="text-primary text-6xl mb-4" />
            <h3 className="font-bold text-2xl dark:text-white">Servidores Linux</h3>
            <p className="text-lg text-gray-700 dark:text-gray-400 mt-2">Configuramos y administramos servidores Linux para un rendimiento óptimo.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Technologies;
