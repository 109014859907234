import React, { useState } from 'react';

const faqs = [
  {
    question: '¿Qué servicios ofrecen?',
    answer: 'Ofrecemos desarrollo web, desarrollo de aplicaciones móviles y soluciones en la nube.',
  },
  {
    question: '¿Cómo puedo contactar con ustedes?',
    answer: 'Puedes contactarnos a través de nuestro formulario de contacto en la sección de Contacto.',
  },
  {
    question: '¿Qué tecnologías utilizan?',
    answer: 'Utilizamos una variedad de tecnologías, incluyendo React, Node.js, y bases de datos SQL y NoSQL, WordPress, Laravel, CodeIgniter, PHP, CSS, HTML, JavaScript, AJAX, jQuery, servidores Linux, Android Studio y Python.',
  },
  {
    question: '¿Cuánto tiempo lleva AEWhite Devs en el mercado?',
    answer: 'AEWhite Devs ha estado en el mercado por más de 3 años, ofreciendo soluciones tecnológicas innovadoras.',
  },
  {
    question: '¿Cuáles son algunos de los proyectos más destacados en los que ha trabajado AEWhite Devs?',
    answer: 'Uno de nuestros proyectos más destacados es MyPymeControl, un sistema completo de gestión de negocios.',
  },
  {
    question: '¿Qué distingue a AEWhite Devs de otros grupos de desarrollo?',
    answer: 'Nos distinguimos por nuestra fiabilidad y porque nos gusta trabajar mano a mano con el cliente.',
  },
  {
    question: '¿Ofrecen servicios de mantenimiento y soporte para los proyectos desarrollados?',
    answer: 'Sí, ofrecemos servicios de mantenimiento y soporte continuo para asegurar que nuestros proyectos funcionen sin problemas.',
  },
  {
    question: '¿Cuáles son los plazos típicos de entrega para los proyectos?',
    answer: 'Los plazos de entrega dependen del proyecto, pero solemos completar los trabajos en aproximadamente la mitad del tiempo que nuestra competencia.',
  },
  {
    question: '¿Ofrecen formación o documentación para los proyectos entregados?',
    answer: 'Sí, proporcionamos formación y documentación completa para asegurar que nuestros clientes puedan gestionar y mantener sus proyectos de manera efectiva.',
  },
  {
    question: '¿Qué tipo de metodología de desarrollo sigue AEWhite Devs?',
    answer: 'Seguimos metodologías ágiles para asegurar una entrega rápida y eficiente, y adaptarnos a los cambios de requerimientos de nuestros clientes.',
  },
  {
    question: '¿Trabajan con clientes de todo el mundo o se enfocan en una región específica?',
    answer: 'Trabajamos con clientes de todo el mundo, ofreciendo soluciones personalizadas que se adaptan a diferentes mercados y necesidades.',
  },
  {
    question: '¿Cuáles son las tarifas típicas de AEWhite Devs para diferentes tipos de proyectos?',
    answer: 'Nuestras tarifas varían según la complejidad y el alcance del proyecto. Para obtener un presupuesto personalizado, los clientes deben ponerse en contacto con nosotros.',
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="faq" className="py-16 bg-gray-800 text-white dark:bg-gray-900">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12 slide-up">Preguntas Frecuentes</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6 dark:bg-gray-700">
              <h3 
                className="font-bold text-xl cursor-pointer dark:text-white flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <span>{openIndex === index ? '-' : '+'}</span>
              </h3>
              {openIndex === index && (
                <p className="text-gray-700 dark:text-gray-400 mt-2">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
