import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 p-8 text-white">
      <div className="container mx-auto text-center">
        <p className="text-1xl font-semibold">&copy; 2024 AEWhite Devs. Todos los derechos reservados.</p>
        <p className="text-gray-400 mt-4">Diseñado y desarrollado por AEWhite Devs</p>
      </div>
    </footer>
  );
}

export default Footer;
